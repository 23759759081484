import { Article, Cancel, CheckCircle, ExpandMore, OpenInNew } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import { Parser } from "html-to-react";
import { useState } from "react";
import useErrorPopup from "../../../tools/hooks/showError";
import axios from "axios";
import { URL } from "../../../tools/url";
import ConfirmDeletePopup from "../../popups/admin/ConfirmDeletePopup";
import { useNavigate } from "react-router-dom";

const InfoPageInList = ({ infoPage, updateInfoPage }) => {
  const navigate = useNavigate();
  const [deleteInfoPagePopup, setDeleteInfoPagePopup] = useState(false);

  const [showError, ErrorPopupComponent] = useErrorPopup();

  const deleteInfoPage = async () => {
    try {
      await axios.post(`${URL}/api/ceDeleteInfoPage/`, {
        info_pageId: infoPage.id,
      });
      updateInfoPage({ id: infoPage.id }, true);
    } catch (err) {
      console.error(err);
      showError("Couldn't delete info page");
    }
  };

  return (
    <>
      <Accordion
        className="staff-course-in-list-lesson"
        style={{
          margin: "10px 0",
          background: "#f9f871",
        }}
      >
        <AccordionSummary expandIcon={<ExpandMore />}>
          <div className="staff-course-in-list-lesson-name">
            <Article /> INFO PAGE: {infoPage.name}{" "}
            <div
              style={{
                marginInlineStart: "5px",
                display: "flex",
                alignItems: "center",
              }}
              title={infoPage.is_active ? "Active" : "Not active"}
            >
              {infoPage.is_active ? (
                <CheckCircle style={{ fontSize: "15px" }} />
              ) : (
                <Cancel style={{ fontSize: "15px" }} />
              )}
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          {infoPage.content ? (
            <div
              className="staff-course-in-list-info-page"
              style={{ background: "white" }}
            >
              {Parser().parse(infoPage.content)}
            </div>
          ) : (
            <i>No content entered</i>
          )}
          <div className="staff-course-in-list-edit-button-wrapper">
            <button
              className="prompt-generation-button"
              onClick={() =>
                navigate(`/admin/courses/edit-info-page/${infoPage.id}`)
              }
              style={{ display: "flex", alignItems: "center" }}
            >
              EDIT INFO PAGE "{(infoPage?.name ?? "").toUpperCase()}"
              <OpenInNew style={{ marginInlineStart: "5px" }} />
            </button>
          </div>
          <div className="staff-course-in-list-edit-button-wrapper">
            <button
              className="prompt-generation-button"
              onClick={() => setDeleteInfoPagePopup(true)}
              style={{
                backgroundColor: "#fa7d5f",
                color: "#282c34",
                fontWeight: "bold",
                margin: "10px 0",
              }}
            >
              DELETE INFO PAGE "{(infoPage?.name ?? "").toUpperCase()}"
            </button>
          </div>
        </AccordionDetails>
      </Accordion>
      <ConfirmDeletePopup
        open={deleteInfoPagePopup}
        close={() => setDeleteInfoPagePopup(false)}
        confirmDelete={deleteInfoPage}
      >
        Are you sure you want to delete this info page?
      </ConfirmDeletePopup>
      {ErrorPopupComponent}
    </>
  );
};

export default InfoPageInList;
