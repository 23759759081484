import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $insertNodeToNearestRoot } from "@lexical/utils";
import { COMMAND_PRIORITY_EDITOR, createCommand } from "lexical";
import { useEffect, useMemo, useRef, useState } from "react";

import { $createYouTubeNode, YouTubeNode } from "../nodes/YouTubeNode";
import Button from "../ui/Button";
import TextInput from "../ui/TextInput";
import { DialogActions } from "../ui/Dialog";

export const INSERT_YOUTUBE_COMMAND = createCommand("INSERT_YOUTUBE_COMMAND");

const YOUTUBE_REGEX = /(?:youtube(?:-nocookie)?\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;

export function InsertYouTubeDialog({ activeEditor, onClose }) {
  const hasModifier = useRef(false);
  const [src, setSrc] = useState("");

  const isDisabled = useMemo(() => !YOUTUBE_REGEX.test(src), [src]);

  useEffect(() => {
    hasModifier.current = false;
    const handler = (e) => {
      hasModifier.current = e.altKey;
    };
    document.addEventListener("keydown", handler);
    return () => {
      document.removeEventListener("keydown", handler);
    };
  }, [activeEditor]);

  const onClick = (payload) => {
    activeEditor.dispatchCommand(INSERT_YOUTUBE_COMMAND, payload);
    onClose();
  };

  return (
    <>
      <TextInput
        placeholder="https://www.youtube.com/watch?v=jNQXAC9IVRw"
        onChange={setSrc}
        value={src}
        data-test-id="youtube-modal-url-input"
      />
      <DialogActions>
        <Button
          data-test-id="youtube-modal-confirm-btn"
          disabled={isDisabled}
          onClick={() => onClick(src.match(YOUTUBE_REGEX)[1])}
        >
          Embed
        </Button>
      </DialogActions>
    </>
  );
}

export default function YouTubePlugin() {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (!editor.hasNodes([YouTubeNode])) {
      throw new Error("YouTubePlugin: YouTubeNode not registered on editor");
    }

    return editor.registerCommand(
      INSERT_YOUTUBE_COMMAND,
      (payload) => {
        const youTubeNode = $createYouTubeNode(payload);
        $insertNodeToNearestRoot(youTubeNode);

        return true;
      },
      COMMAND_PRIORITY_EDITOR
    );
  }, [editor]);

  return null;
}
