//packages
import React from "react";
import { useNavigate } from "react-router-dom";
//style
import "../../style/chatLoader.css";
//tools
import { vh } from "../../tools/screen";
//stores
import chat from "../../stores/chat.store";
import { Dialog } from "@mui/material";

const ChatLoader = ({
  setLoadDisplay,
  loading,
  setLoading,
  setAvatar,
  scenario,
  open,
}) => {
  const navigate = useNavigate();

  const goBack = async () => {
    if (localStorage.getItem("courseId"))
      navigate(`/course/${localStorage.getItem("courseId")}`);
    else navigate("/");
  };

  const colors = [
    "#D5E2E0",
    "#F8EAEC",
    "#CAE0E4",
    "#F7DDD9",
    "#F7E6DA",
    "#EEF3E9",
    "#C8C7D6",
    "#F9E0E2",
    "#E3E9DD",
    "#C4DBD9",
    "#E4E4EC",
    "#F0D7DF",
    "#FFF3EB",
  ];

  return (
    <Dialog
      open={open}
      PaperProps={{
        style: {
          height: "fit-content",
          display: "flex",
          flexDirection: "column",
          height: "70vh",
          width: "65vw",
          backgroundColor: "white",
          borderRadius: "2vh",
          maxHeight: "unset",
          maxWidth: "unset",
          overflow: "hidden",
          boxSizing: "border-box",
        },
        className: "chat-loader-paper"
      }}
    >
      <div
        className="backLoad"
        onClick={() => {
          goBack();
        }}
      >
            <img
                src={`/images/${loading ? "whiteBack" : "back"}.png`}
                alt="back"
            />
            <h2 style={{ color: loading ? "#fff" : "#262d3b"}}>Home</h2>
      </div>

      {loading ? (
        <div className="topLoader">
          <div className="loadComponent">
            <h2 className="loadTitle">Loading conversation</h2>
            <div className="loadingio-spinner-ellipsis-3luurm4d5ky">
              <div className="ldio-wbsk1rjduxb">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          </div>
          <h2
            className="loadTitle"
            style={{
              fontSize: "20px",
              fontWeight: "normal",
            }}
          >
            {" "}
            This can take a minute or two. Please read the info below and
            prepare to start:
          </h2>
        </div>
      ) : (
        <div
          className="topLoader"
          style={{
            backgroundColor: "#0ae5a0",
            cursor: "pointer",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
          onClick={() => {
            setLoadDisplay(false);
          }}
        >
          <div
            className="loadComponent" style={{ margin: 0}}
          >
            <h2
              className="loadTitle"
              style={{ color: "#262d3b", fontWeight: "bolder" }}
            >
              Ready, tap to start conversation
            </h2>
          </div>
        </div>
      )}
      <div className="homeInfoDiv">
        <h2 className="homeConvTitle" style={{ fontWeight: "bold" }}>
          {" "}
          {scenario?.name}
        </h2>

        <h2 className="homeConvDescription">
          {scenario?.user_instructions}
        </h2>

        <h2
          className="homeConvDescription"
        >
          skills you will practice in this lesson:
        </h2>

        <div className="homeInfoskills">
            {chat.chatSettings.skills?.map((skill, index) => {
              let col = colors.length - 1;
              let colorDisplayId = index - Math.floor(index / col) * col;
              return (
                <div
                  key={index}
                  style={{ backgroundColor: colors[colorDisplayId] }}
                  className="homeInfoskill"
                >
                  <h2>{skill}</h2>
                </div>
              );
            })}
          </div>
      </div>

      {/* {!loading ?
                    <h2
                        onClick={() => setLoadDisplay(false)}
                        className="loadTitle"
                        style={{ marginTop: vh(5), textDecoration: 'underline', cursor: 'pointer' }}> Start conversation</h2> : <></>
                } */}
    </Dialog>
  );
};

export default ChatLoader;
