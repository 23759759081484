import Button from "../button";
import chat from "../../stores/chat.store";
import { StepLabel, StepContent, IconButton } from "@mui/material";
import ConvInfo from "../../components/popups/convInfoHomePage";
import { URL } from "../../tools/url";
import NoTokens from "../popups/noTokens";
import axios from "axios";
import { useEffect, useState } from "react";
import { useAuth } from "../../context/AuthContext";

const ListedScenario = ({ scenario, devMode, navigate, onClick, isActive }) => {
  const [infoPopupOpen, setInfoPopupOpen] = useState(false);
  const [noTokensPopup, setNoTokensPopup] = useState(false);

  const [scenarioInfo, setScenarioInfo] = useState({ ...scenario });

  const [tokens, setTokens] = useState(0);

  const [canPlay, setCanPlay] = useState(false);
  const { user } = useAuth();

  const getScenarioInfo = async () => {
    try {
      const { data } = await axios.get(
        `${URL}/api/get_scenario?user_id=${user.id}&scenario_id=${scenario.id}`
      );
      setScenarioInfo(data);
      console.log('scenario', data)
    } catch (err) {
      console.error(err);
    }
  };

  const checkTokens = async () => {
    try {
      const { data } = await axios.get(
        `${URL}/api/checkTokens/?userId=${user.id}`
      );
      return data.tokens;
    } catch (e) {
      return 1;
    }
  };

  const startConversation = async (avatar = true) => {
    if (canPlay) {
      chat.setConversation(scenario.name);
      chat.setConvoDescription(scenario.user_instructions);
      chat.setConversationId(scenario.id);
      // chat.setTopic(scenario.topicName);
      navigate(`/scenario${avatar ? "" : "Dev"}/${scenario.id}`);
    } else {
      if (!tokens) setNoTokensPopup(true);
      else setInfoPopupOpen(true);
    }
  };

  const getTokens = async () => {
    const tokensFromServer = await checkTokens();
    setTokens(tokensFromServer);
  };

  useEffect(() => {
    if (scenario && user.id) {
      getScenarioInfo();
      getTokens();
    }
  }, [scenario, user]);

  useEffect(() => {
    if (
      scenarioInfo?.required_user_info_fields && // did the user info array get loaded
      (!scenarioInfo?.required_user_info_fields?.length || // no required user info
        !scenarioInfo?.required_user_info_fields.some( // no unanswered user info
          (item) => !item.answer.length
        )) &&
      tokens // user has enough tokens
    ) {
      setCanPlay(true);
    } else {
      setCanPlay(false);
    }
  }, [scenarioInfo, tokens]);

  return (
    <>
      <StepLabel
        sx={{
          "& *": { cursor: "pointer" },
          p: 0,
          height: "25px",
        }}
        onClick={onClick}
        StepIconComponent={() =>
          scenario?.status === "done" ? (
            <img
              src="/images/lessonNumN.png"
              alt="number decore"
              className="lessonNum"
            />
          ) : (
            <div className="lessonNum"></div>
          )
        }
      >
        <h3 className="lessonsTitle">
          Scenario: {scenarioInfo?.name}{" "}
          <IconButton
            onClick={() => setInfoPopupOpen(true)}
            className="lessonInfo"
          >
            <img src="/images/information.png" alt="info" />
          </IconButton>
        </h3>
      </StepLabel>
      <StepContent
        sx={
          isActive
            ? { p: "10px 25px 0 25px" }
            : {
                /*height: 0*/
              }
        }
      >
        <div
          style={{ marginTop: "10px", display: "flex", alignItems: "center" }}
        >
          <Button
            text="Start"
            icon="/images/playWhite.png"
            onClick={() => startConversation(true)}
            style={{ margin: "0 20px" }}
          />
          {devMode && (
            <Button
              text="Start without avatar"
              icon="/images/playWhite.png"
              onClick={() => startConversation(false)}
            />
          )}
        </div>
      </StepContent>
      <ConvInfo
        scenario={{ ...scenarioInfo }}
        close={() => setInfoPopupOpen(false)}
        open={infoPopupOpen}
        canContinue={canPlay}
        userId={user.id}
        onContinue={() => startConversation(true)}
        fetchInfo={getScenarioInfo}
      />
      <NoTokens
        open={noTokensPopup}
        close={() => {
          setNoTokensPopup(false);
        }}
      />
    </>
  );
};

export default ListedScenario;
