//packages
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
//style
import "../../style/convInfoPopup.css";
//stores
import chat from "../../stores/chat.store";
//tools
import Button from "../button";
// components
import ScenarioUserInfo from "../user-info/ScenarioUserInfo";
import { Dialog, IconButton } from "@mui/material";
import { CloseRounded } from "@mui/icons-material";

const ConvInfo = ({
  scenario,
  close,
  open,
  canContinue,
  userId,
  onContinue,
  fetchInfo,
  fromChat = false
}) => {
  const navigate = useNavigate();

  const [skills, setSkills] = useState([]);
  const [userInfoQuestions, setUserInfoQuestions] = useState([]);

  useEffect(() => {
    setSkills(scenario?.active_skills ? scenario.active_skills.map(item => item.name) : []);
    setUserInfoQuestions(scenario?.required_user_info_fields);
  }, [scenario]);

  const colors = [
    "#D5E2E0",
    "#F8EAEC",
    "#CAE0E4",
    "#F7DDD9",
    "#F7E6DA",
    "#EEF3E9",
    "#C8C7D6",
    "#F9E0E2",
    "#E3E9DD",
    "#C4DBD9",
    "#E4E4EC",
    "#F0D7DF",
    "#FFF3EB",
  ];

  return (
    <Dialog
      open={open}
      PaperProps={{
        style: {
          height: "fit-content",
          display: "flex",
          flexDirection: "column",
          height: "70vh",
          width: "65vw",
          backgroundColor: "white",
          borderRadius: "1vh",
          maxHeight: "unset",
          maxWidth: "unset",
          overflow: "hidden",
          padding: "30px 35px",
          boxSizing: "border-box"
        },
      }}
    >
      <div style={{ position: "relative", height: "100%", width: "100%" }}>
        <IconButton className="exitInfo" onClick={close} sx={{ color: "#262d3b", padding: "1px" }}>
          <CloseRounded sx={{ fontSize: "35px" }} />
        </IconButton>
        <div className="homeInfoDiv">
          <h2 className="homeConvTitle">Lesson: {scenario?.name}</h2>

          <h2 className="homeConvDescription">{scenario?.user_instructions}</h2>
          {skills.length ?
            <>
              <h2
                className="homeConvDescription"
                style={{ marginTop: "25px", fontSize: "20px" }}
              >
                skills you will practice in this lesson:
              </h2>

              <div className="homeInfoskills">
                {skills.map((skill, index) => {
                  let col = colors.length - 1;
                  let colorDisplayId = index - Math.floor(index / col) * col;
                  return (
                    <div
                      key={index}
                      style={{ backgroundColor: colors[colorDisplayId] }}
                      className="homeInfoskill"
                    >
                      <h2>{skill}</h2>
                    </div>
                  );
                })}
              </div>
            </> : <></>
          }
          {userInfoQuestions?.length ? (
            <ScenarioUserInfo
              fields={userInfoQuestions}
              userId={userId}
              canStartConversation={canContinue}
              onAnswer={fetchInfo}
            />
          ) : (
            <></>
          )}
        </div>

        <div className="infoButtonContainer">
          {fromChat ? (
            <Button
              text="Continue"
              icon="/images/playWhite.png"
              style={{
                backgroundColor: canContinue ? "#262D3B" : "#aaa",
                cursor: canContinue ? "pointer" : "not-allowed",
                fontWeight: "400",
              }}
              alt="start"
              onClick={() => {
                if (canContinue) onContinue();
              }}
            />
          ) : (
            <Button
              text="Start"
              icon="/images/playWhite.png"
              style={{
                backgroundColor: canContinue ? "#262D3B" : "#aaa",
                cursor: canContinue ? "pointer" : "not-allowed",
                fontWeight: "400",
              }}
              alt="start"
              onClick={() => {
                if (canContinue) {
                  chat.setConversation(scenario?.name); //Set values in chat store
                  chat.setConvoDescription(scenario?.user_instructions);
                  chat.setConversationId(scenario?.id);
                  chat.setConvSkills(skills);
                  navigate(`/scenario/${scenario?.id}`);
                }
              }}
            />
          )}
        </div>
      </div>
    </Dialog>
  );
};

export default ConvInfo;
