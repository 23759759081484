import React, { useEffect, useState } from "react";
import { Unity, useUnityContext } from "react-unity-webgl";
import axios from 'axios'
//style
import '../style/unityAvatar.css'
//tools
import { UNITYFILES } from "../tools/url";
import { vh, vw } from "../tools/screen";


function UnityAvatar({ speechText, setSpeechText, setSpeaking, avatar, Loading, setLoading, setEmotion, emotion, imageId }) {

    const { unityProvider, sendMessage } = useUnityContext({ ...UNITYFILES });
    const [backgroundImage, setBackgroundImage] = useState('')

    const LoadBackgroundImageFromServer = async () => {
        //when conv info will be fetched from the server change chatbackgriund function to actual url.
        const backgroundURL = ChatBackground()
        //converts image from url to base 64
        const response = await axios.post(`https://alpha.ar-rows.com:8081/api/fetchImage?url=${backgroundURL}`);
        const base64String = response.data.split(",")[1];
        sendMessage("ImageCTRL", "LoadImage", base64String);
        console.log("loaded background", backgroundURL)

    }
    useEffect(() => {
        if (!Loading && backgroundImage) {
            LoadBackgroundImageFromServer()
        }
    }, [Loading, backgroundImage]);

    useEffect(() => {
        const handleMessage = (event) => {
            if (event.data === 'Loaded') {
                setLoading(false);
            }
            if (event.data === 'Done') {
                setSpeaking(false);
                setSpeechText('')

            }
        };
        window.addEventListener('message', handleMessage);
        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, []);


    useEffect(() => {
        setBackgroundImage(imageId)
    }, [imageId]);
    
    useEffect(() => {
        if (speechText) {
            const GenerateAndPlayAudio = async () => {
                try {
                    setSpeaking(true)
                    let res = await axios.post(`https://alpha.ar-rows.com:8081/api/generateAudioFile?text=${speechText}`);
                    sendMessage("Avatar", "SendAudio", `${_arrayBufferToBase64(res.data.audioFile.data)}`);
                }
                catch (err) {
                    console.log(err);
                }
            }
            GenerateAndPlayAudio();
        } else {
            setSpeaking(false);
        }
    }, [speechText])

    useEffect(() => {
        if (emotion) {
            const ActivateEmotion = async () => {
                try {
                    sendMessage("AnimationCTRL", "PlayEmotion", emotion);
                    setEmotion('')
                }
                catch (err) {
                    console.log(err);
                }
            }
            ActivateEmotion();
        }
    }, [emotion])

    function _arrayBufferToBase64(buffer) {
        var binary = '';
        var bytes = new Uint8Array(buffer);
        var len = bytes.byteLength;
        for (var i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return window.btoa(binary);
    }


    const ChatBackground = () => {
        if (backgroundImage === 'stranger at the uni open day') {
            return (
                "https://alpha.ar-rows.com/images/convBackgrounds/UniOpenDay.png"
            )
        }
        else if (backgroundImage === 'meeting liz after summer break') {
            return (
                "https://alpha.ar-rows.com/images/convBackgrounds/coffeePlace.png"
            )
        }
        else if (backgroundImage === 'meeting liz at the bus station') {
            return (
                "https://alpha.ar-rows.com/images/convBackgrounds/busStation.png"
            )
        }
        else {
            return (
                "https://alpha.ar-rows.com/images/convBackgrounds/coffeePlace.png"
            )
        }
    }

    return (
        <div className="unityAvatarContainer">
            {avatar ?
                <div id="unityWrapper">
                    <Unity unityProvider={unityProvider} style={{ height: vw(31), width: vw(55), borderRadius: vh(2) }} />
                </div>
                : <img src="/images/AvatarGenNoB.png" alt="avatar" id="botImage" style={{ height: vh(70) }} />
            }
        </div>
    );
}

export default UnityAvatar;




