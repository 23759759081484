import './Dialog.css';

export function DialogButtonsList({children}) {
  return <div className="EditorDialogButtonsList">{children}</div>;
}

export function DialogActions({
  'data-test-id': dataTestId,
  children,
}) {
  return (
    <div className="EditorDialogActions" data-test-id={dataTestId}>
      {children}
    </div>
  );
}