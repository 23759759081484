import Button from "../button";
import { StepLabel, StepContent } from "@mui/material";

const ListedInfoPage = ({ infoPage, navigate, onClick, isActive }) => (
  <>
    <StepLabel
      sx={{
        "& *": { cursor: "pointer" },
        p: 0,
        height: "25px",
      }}
      onClick={onClick}
      StepIconComponent={() =>
        infoPage.status === "done" ? (
          <img
            src="/images/lessonNumN.png"
            alt="number decore"
            className="lessonNum"
          />
        ) : (
          <div className="lessonNum"></div>
        )
      }
    >
      <h3 className="lessonsTitle">Lesson: {infoPage.name}</h3>
    </StepLabel>
    <StepContent sx={isActive ? { p: "10px 25px 0 25px" } : { /*height: 0*/ }}>
      <Button
        text="Read"
        icon="/images/read.png"
        style={{
          margin: "10px 0 0 20px",
          backgroundColor: "#10a0a0a0",
          fontWeight: "400",
        }}
        alt="read"
        onClick={() => {
          navigate(`/info-page/${infoPage.id}`);
        }}
      />
    </StepContent>
  </>
);

export default ListedInfoPage;
