import "./Input.css";

export default function FileInput({
  accept,
  label,
  onChange,
  "data-test-id": dataTestId,
}) {
  return (
    <div className="EditorInput__wrapper">
      <label className="EditorInput__label">{label}</label>
      <input
        type="file"
        accept={accept}
        className="EditorInput__input"
        onChange={(e) => onChange(e.target.files)}
        data-test-id={dataTestId}
      />
    </div>
  );
}
