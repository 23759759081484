//packages
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition";
//stores
import chat from "../stores/chat.store";
//components
import ChatBar from "../components/chatBar";
import UnityAvatar from "../components/unityAvatar";
import ChatComponent from "../components/chatHistory";
import ChatLoader from "../components/popups/chatLoadingPopup";
import CoachChatHistory from "../components/coachChatHistory";
//style
import "../style/chat.css";
import axios from "axios";
import { URL } from "../tools/url";
import { useAuth } from "../context/AuthContext";

const ChatPage = () => {
  // stores
  const chatStore = chat;

  // states
  const [reset, setReset] = useState(false);
  // const [record, setRecord] = useState(false);
  const [speaking, setSpeaking] = useState(false);
  const [speechText, setSpeechText] = useState("");
  const [emotion, setEmotion] = useState("");
  const [open, setOpen] = useState(false); //handles info popup visibility
  const [allowSubmit, setAllowSubmit] = useState(false);
  const [DidMODE, setDiDMODE] = useState(false);
  const [avatar, setAvatar] = useState(true);
  const [loading, setLoading] = useState(true);
  const [loadDisplay, setLoadDisplay] = useState(true);
  const [endChatMode, setEndChatMode] = useState(false);
  const [textMap, setTextMap] = useState({
    backHome: "Home",
  });

  const [scenarioMetadata, setScenarioMetadata] = useState(null);

  // hooks
  const navigate = useNavigate();
  const [ searchParams ] = useSearchParams();
  const url_params = useParams();
  const { user } = useAuth();

  const language = useMemo(() => searchParams.get('language') || "en", [searchParams]);


  const {
    transcript,
    resetTranscript,
    listening
  } = useSpeechRecognition();

  useEffect(() => {
    if (language === "he") {
      setTextMap({
        backHome: "חזרה לדף הבית",
      });
    } else {
      setTextMap({
        backHome: "Home",
      });
    }
  }, [language]);

  const fetchScenario = async (scenario_id, user_id) => {
    try {
      const { data } = await axios.get(`${URL}/api/get_scenario/`, {
        params: { scenario_id, user_id },
      });
      console.log(data)
      setScenarioMetadata(data);
    } catch (err) {
      if (err.status === 401) alert(err.response ? err.response.data : err.message);
      console.error(err);
    }
  };

  useEffect(() => {
    if (user && url_params?.id) fetchScenario(url_params?.id, user.id);
  }, [user, url_params]);

  const startRecording = async () => {
    await SpeechRecognition.startListening({ continuous: true, language: language === 'he' ? 'he-IL' : 'en-US' });
  }

  const stopRecording = async () => {
    await SpeechRecognition.stopListening();
  }

  const abortRecording = async () => {
    await SpeechRecognition.abortListening();
  }

  useEffect(() => {
    if (window.location.pathname.includes("scenarioDev")) {
      setAvatar(false);
      setLoadDisplay(false);
    }
    setEndChatMode(false);
    return async () => await abortRecording();
  }, []);

  let goBack = async () => {
    chatStore.endChat();
    if (localStorage.getItem("courseId"))
      navigate(`/course/${localStorage.getItem("courseId")}`);
    else navigate("/");
  };

  //this function is passed down to the chatBar component, and is executed there
  const resetChat = () => {
    setReset(true);
    chatStore.endChat();
  };

  return (
    <div className="chat-page">
      <ChatLoader
        open={avatar && loadDisplay}
        setLoadDisplay={setLoadDisplay}
        loading={loading}
        setLoading={setLoading}
        setAvatar={setAvatar}
        scenario={scenarioMetadata}
      />

      <div className="container">
        {!endChatMode ? (
          <div className="left">
            <div className="centered">
              <div
                className="back"
                onClick={goBack}
              >
                <img src="/images/back.png" alt="back" />
                <h2>{textMap?.backHome}</h2>
              </div>

              <div className="avatarDisplay">
                <UnityAvatar
                  setSpeaking={setSpeaking}
                  speechText={speechText}
                  setSpeechText={setSpeechText}
                  setEmotion={setEmotion}
                  emotion={emotion}
                  avatar={avatar}
                  setLoading={setLoading}
                  Loading={loading}
                  imageId={scenarioMetadata?.id}
                />
              </div>

              <div className="chatBar">
                <ChatBar
                  language={language}
                  chatRestart={resetChat}
                  open={open}
                  setOpen={setOpen}
                  scenario={scenarioMetadata}
                />
              </div>
            </div>
          </div>
        ) : (
          <>
            <CoachChatHistory />
          </>
        )}

        <div className="right">
          <ChatComponent
            language={language}
            chatRestart={resetChat}
            allowSubmit={allowSubmit}
            setAllowSubmit={setAllowSubmit}
            reset={reset}
            setReset={setReset}
            listening={listening}
            startRecording={startRecording}
            stopRecording={stopRecording}
            abortRecording={abortRecording}
            transcript={transcript}
            resetTranscript={resetTranscript}
            speaking={speaking}
            setSpeaking={setSpeaking}
            setSpeechText={setSpeechText}
            setEmotion={setEmotion}
            DidMODE={DidMODE}
            setDiDMODE={setDiDMODE}
            avatar={avatar}
            loadDisplay={loadDisplay}
            endChatMode={endChatMode}
            setEndChatMode={setEndChatMode}
          />
        </div>
      </div>
    </div>
  );
};

export default ChatPage;
