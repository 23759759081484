
import { makeObservable, observable, action } from 'mobx';
import axios from 'axios';
import { URL } from '../tools/url';

class Chat {
  constructor() {
    makeObservable(this, {
      chatSettings: observable,
      setTopic: action,
      setConversation: action,
      setConvoDescription: action,
      startChat: action,
      sendUserResponse: action,
      endChat: action,
      setPerformance: action,
      performance: observable,
      performanceArr: observable,
      cueSources: observable,
      logout: action,
      setBotName: action,
      skills: observable,
      setSkills: action

    });
    this.loadFromLocalStorage();
  }

  chatSettings = { name: '', email: '', sessionId: '', botName: '', topic: '', conversation: '', conversationId: '', convoDescription: '', skills: [] };
  performance = {}
  performanceArr = []
  skills = []

  cueSources = {
    Elaborate: "/images/cues/Elaborate.png",
    DontBeRepetitive: "/images/cues/DontBeRepetitive.png",
    DontBrag: "/images/cues/DontBrag.png",
    DontInterview: "/images/cues/DontInterview.png",
    Share: "/images/cues/Share.png",
    DontAskClosedQuestions: "/images/cues/DontAskClosedQuestions.png",
    AskOEQuestions: "/images/cues/AskOEQuestions.png",
    DontGetTooPersonal: "/images/cues/DontGetTooPersonal.png",
    DontHogConversation: "/images/cues/DontHogConversation.png",
    DontPolice: "/images/cues/DontPolice.png",
    DontTease: "/images/cues/DontTease.png",
  }

  setSkills(skills) {
    this.skills = skills;
    localStorage.setItem('skills', JSON.stringify(skills));

  }
  setPerformance(newKey) {
    this.performance = { ...this.performance, ...newKey };
    if (this.performance.startingDIDStream) {
      this.performanceArr.push(this.performance)
      this.performance = {}
    }

    if (this.performanceArr.length === 5) {
      let sumServer = 0;
      let sumBeforeTalk = 0;
      let sumAfterTalk = 0;
      let sumStartDID = 0;

      this.performanceArr.forEach((performance) => {
        sumServer = sumServer + performance.serverRes;
        sumBeforeTalk = sumBeforeTalk + performance.beforeTalk;
        sumAfterTalk = sumAfterTalk + performance.afterTalk;
        sumStartDID = sumStartDID + performance.startingDIDStream;
      })
      console.log("average speed for last 5 runs:",
        "server response time:", sumServer / 5 + "s",
        "time before calling talk function:", sumBeforeTalk / 5 + "s",
        "time after calling talk function:", sumAfterTalk / 5 + "s",
        "time untill D-id starts stream:", sumStartDID / 5 + "s",
      );
      this.performanceArr = []
    }
  }

  setName = (userName) => {
    this.chatSettings.name = userName;
    this.saveToLocalStorage();
  };
  setEmail = (email) => {
    this.chatSettings.email = email;
    this.saveToLocalStorage();
  };
  setTopic = (topic) => {
    this.chatSettings.topic = topic;
    this.saveToLocalStorage();
  };
  setSessionId = (sessionId) => {
    this.chatSettings.sessionId = sessionId;
    this.saveToLocalStorage();
  };

  setConversation = (conversation) => {
    this.chatSettings.conversation = conversation;
    this.saveToLocalStorage();
  };
  setConversationId = (conversationId) => {
    this.chatSettings.conversationId = conversationId;
    this.saveToLocalStorage();
  };

  setConvoDescription = (convoDescription) => {
    this.chatSettings.convoDescription = convoDescription;
    this.saveToLocalStorage();
  };
  setConvSkills = (skills) => {
    this.chatSettings.skills = skills;
    this.saveToLocalStorage();
  };
  startChat = async () => {
    const { data } = await axios.get(`${URL}/api/startConversation/?config=${this.chatSettings.name}&convId=${this.chatSettings.conversationId}&userName=${this.chatSettings.email}&sessionId=${this.chatSettings.sessionId}`);
    this.saveToLocalStorage();
    return data;
  };

  setBotName = (name) => {
    name ? this.chatSettings.botName = name : this.chatSettings.botName = 'Avatar'
  }

  sendUserResponse = async (msg) => {
    let currChats = [];
    let feedbacks = [];
    const { data } = await axios.get(`${URL}/api/userTurn/?msg=${msg}&sessionId=${this.chatSettings.sessionId}`);
    currChats.push({
      type: 'bot', message: data.res, status: data.currentState, socialParams: data.socialParams, id: data.userTurnId, emotion: data.res_facial_expression,
    });

    if (data.cue !== null && this.cueSources[data.cue.name]) {
      feedbacks.push({ type: "cue", display: this.cueSources[data.cue.name], title: data.cue.display, text: data.cue.explanation, id: data.cue.skillId })
    }
    else if (data.cue !== null) {
      feedbacks.push({ type: "cue", display: null, title: data.cue.display, text: data.cue.explanation, id: data.cue.skillId })
    }
    else if (data.posFeedbacks !== null) {
      feedbacks.push({ type: "posFeedback", display: data.posFeedbacks[0].title, title: 'Good job!', text: data.posFeedbacks[0].description, id: data.posFeedbacks[0].skillId })
    }
    return {
      id: data.userTurnId, chats: currChats, emotion: data.res_facial_expression
      , feedbacks: feedbacks, endChat: { status: data.endConvStatus, message: data.endConversation },
    };
  };

  endChat = () => {
  };

  // Save chat data to local storage
  saveToLocalStorage = async () => {
    localStorage.setItem('chatData', JSON.stringify({ chatSettings: this.chatSettings }));
  }



  // Load chat data from local storage
  loadFromLocalStorage() {
    const storedData = localStorage.getItem('chatData');
    if (storedData) {
      const { chatSettings } = JSON.parse(storedData);
      this.chatSettings = chatSettings;
    }
  }

  logout() {
    localStorage.clear();
    window.dispatchEvent(new Event('storage'))
  }
}

const chat = new Chat();

export default chat;
