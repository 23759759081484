import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Unit from "./Unit";
import CourseSkeleton from "../skeletons/courseSk";
import chat from "../../stores/chat.store";
import { URL } from "../../tools/url";
import { Step, Stepper } from "@mui/material";
import { useAuth } from "../../context/AuthContext";

const Course = ({ courseId }) => {
  const navigate = useNavigate();
  const { isAdmin, user } = useAuth();
  const [loading, setLoading] = useState(true);
  const [units, setUnits] = useState([]);
  const [devMode, setDevMode] = useState(false);
  const [userPlacement, setUserPlacement] = useState([0, 0]);

  useEffect(() => {
    const fetchCourseData = async () => {
      setLoading(true);
      try {
        const { data } = await axios.get(`${URL}/api/get_course`, {
          params: { user_id: user.id, course_id: courseId },
        });
        // chat.setSkills(data.skills);
        setUnits(initializeUnits(data.units));
      } catch (error) {
        console.error("Error fetching course data:", error);
      }
      setLoading(false);
    };

    fetchCourseData();
    setDevMode(isAdmin);
  }, []);

  const initializeUnits = (unitsData) => {
    return unitsData.map((unit) => ({
      ...unit,
      status: "done",
      items: [
        ...unit.scenarios.map((item) => {
          return {
            ...item,
            type: "scenario",
          };
        }),
        ...unit.info_pages.map((item) => {
          return {
            ...item,
            type: "info-page",
          };
        }),
      ]
        .sort((a, b) => (a.order < b.order ? -1 : 1))
        .map((conv) => ({ ...conv, status: "done" })),
    }));
  };

  return (
    <div
      className="progressBar"
      style={
        loading
          ? {
              overflow: "hidden",
              display: "flex",
              flexDirection: "column",
            }
          : {}
      }
    >
      {loading ? (
        <CourseSkeleton />
      ) : (
        <div>
          {units.length ? (
            <Stepper orientation="vertical" className="unit-stepper">
              {units.map((unit, index) => {
                return (
                  <Step
                    completed={unit.status === "done"}
                    key={index}
                    sx={{ p: 0 }}
                    active={index === userPlacement[0]}
                  >
                    <Unit
                      unit={unit}
                      index={index}
                      devMode={devMode}
                      navigate={navigate}
                      userPlacement={userPlacement}
                      setUserPlacement={setUserPlacement}
                    />
                  </Step>
                );
              })}
            </Stepper>
          ) : (
            <h2>coming soon...</h2>
          )}
        </div>
      )}
    </div>
  );
};

export default Course;
