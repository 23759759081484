//packages
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ReactGA from "react-ga4";
import axios from "axios";
import { Parser } from "html-to-react";
//components
import PageHeader from "../components/header";
import Button from "../components/button";
//tools
import { URL } from "../tools/url";
//context
import { useAuth } from "../context/AuthContext";
//styles
import "../style/chapterSum.css";

const ChapterSum = () => {
  const navigate = useNavigate();
  const url_params = useParams();
  const { user } = useAuth();

  const [loading, setLoading] = useState(true);
  const [content, setContent] = useState("");
  const [title, setTitle] = useState("");

  useEffect(() => {
    const fetchInfoPage = async () => {
      setLoading(true);
      try {
        const { data } = await axios.get(`${URL}/api/get_info_page/`, {
          params: { info_page_id: url_params?.id, user_id: user.id },
        });
        setContent(data?.content);
        setTitle(data?.name);
      } catch (err) {
        if (err.status === 401) setContent(err.response ? err.response.data : err.message);
        console.error(err);
      }
      setLoading(false);
    };
    if (user) fetchInfoPage();
  }, [user]);

  return (
    <div style={{ width: "100vw", height: "100vw", overflow: "hidden" }}>
      <PageHeader />
      <div className="chapterSumContainer">
        <div
          className="back"
          onClick={() => {
            if (localStorage.getItem("courseId"))
              navigate(`/course/${localStorage.getItem("courseId")}`);
            else navigate("/");
          }}
        >
          <img src="/images/back.png" alt="back" />
          <h2>Home</h2>
        </div>
        {loading ? (
          <></>
        ) : (
          <div className="info-page-content">
            <h2 className="info-page-title">{title ?? "Error"}</h2>
            {content ? (
              <div className="info-page-md">{Parser().parse(content)}</div>
            ) : (
              <div>No content available</div>
            )}

            <Button
              text="Continue"
              icon="/images/playWhite.png"
              style={{
                marginTop: "20px",
                backgroundColor: "#262D3B",
              }}
              alt="Continue"
              onClick={() => {
                ReactGA.event({
                  category: "User",
                  action: "reading complete",
                });
                if (localStorage.getItem("courseId"))
                  navigate(`/course/${localStorage.getItem("courseId")}`);
                else navigate("/");
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ChapterSum;
