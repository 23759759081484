import { useEffect, useState } from "react";
import AdminPage from "../components/admin/AdminPage";
import "../style/editCourse.css";
import axios from "axios";
import { URL } from "../tools/url";
import useErrorPopup from "../tools/hooks/showError";
import { useParams } from "react-router-dom";
import { Checkbox, FormControlLabel } from "@mui/material";
import InfoPageEditor from "../components/admin/course/text-editor/InfoPageEditor";

const EditInfoPage = () => {
  const [infoPage, setInfoPage] = useState(null);

  const [name, setName] = useState("");
  const [active, setActive] = useState(false);
  const [content, setContent] = useState("");

  useEffect(() => {
    setName(infoPage?.name ?? "");
    setActive(infoPage?.is_active ?? false);
    setContent(infoPage?.content ?? "");
  }, [infoPage]);

  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);

  const [showError, ErrorPopupComponent] = useErrorPopup();

  const url_params = useParams();

  useEffect(() => {
    const fetchInfoPageData = async (infoPageId) => {
      setLoading(true);
      try {
        const { data } = await axios.get(
          `${URL}/api/ceGetEditableInfoPageInfo/?infoPageId=${infoPageId}`
        );
        setInfoPage(data.info_page);
      } catch (err) {
        console.error(err);
        showError("Couldn't fetch info page data");
      }
      setLoading(false);
    };
    if (url_params && url_params?.id) {
      fetchInfoPageData(url_params?.id);
    }
  }, [url_params]);

  const saveInfoPage = async (infoPageData) => {
    setSaving(true);
    try {
      const { data } = await axios.post(`${URL}/api/ceSaveInfoPage/`, {
        ...infoPageData,
      });
      setInfoPage(data);
      showError("Saved!", "success");
    } catch (err) {
      console.error(err);
      showError("Couldn't save info page");
    }
    setSaving(false);
  };

  return (
    <AdminPage currentLocation="Edit scenario">
      <div className="prompt-generation-container" style={{ width: "1000px"}}>
        <h1>Edit info page</h1>
        {loading ? (
          <>loading...</>
        ) : (
          <div style={{ position: "relative", width: "100%", padding: "10px 0"}}>
            <div className="prompt-generation-input">
              <label htmlFor="info-page-name">Info page name</label>
              <textarea
                className="prompt-generation-textarea"
                style={{ height: "fit-content" }}
                name="info-page-name"
                placeholder="Enter info page name..."
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <br />
            <div>
              <label>Content</label>
              <InfoPageEditor
                initialHtml={infoPage?.content}
                onHtmlChanged={(htmlContent) => setContent(htmlContent)}
              />
            </div>
            <br />
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={active}
                    onChange={() => setActive((prev) => !prev)}
                    sx={{
                      "&.Mui-checked": {
                        color: "#0ae5a1",
                      },
                    }}
                  />
                }
                label="Active"
                labelPlacement="start"
                sx={{ m: 0, fontFamily: "Assistant", fontSize: "1rem" }}
              />
            </div>
            <div>
              <button
                className="prompt-generation-button"
                disabled={saving}
                onClick={() => {
                  saveInfoPage({
                    ...infoPage,
                    name: name,
                    is_active: active,
                    content: content,
                  });
                }}
              >
                {saving ? "SAVING..." : "SAVE"}
              </button>
            </div>
          </div>
        )}
      </div>
      {ErrorPopupComponent}
    </AdminPage>
  );
};

export default EditInfoPage;
